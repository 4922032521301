import React from 'react'
import { SQL } from '@jelly/templates'

import ADP from '../../../images/lp/employee-retirement-plans/ADPLogo.png'
import HumantInterest from '../../../images/lp/employee-retirement-plans/HumanInterestLogo.png'
import ShareBuilder from '../../../images/lp/employee-retirement-plans/ShareBuilderLogo.png'

const Paychex = `https://www.business.com/images/rz/5cab8a177b43742a038b457e/Paychex_logo.png`
const American401k = `https://www.business.com/images/rz/618c3eaa9c658a21758b4567/american_logo.png`

const EmployeeRetirementPlansMeta = {
	description: `Answer a few questions about your purchase, we'll match you with pre-screened sellers, and receive customized employee retirement price quotes today`,
	robots: `noindex, nofollow`,
	title: `Employee Retirement Price Quotes from Qualified Sellers`,
	canonical: `${process.env.GATSBY_SITE_HOST}/lp/compare-employee-retirement-plans/`,
}

const HEADER_DATA = {
	bannerText: `Fast and free. Receive up to 5 quotes from Employee Retirement brands by answering a few simple questions.`,
	taglineText: `SMART BUSINESS PURCHASES DELIVERED`,
	headerText: `Compare Employee 401k Plans and Save`,
	captionText: `Quickly compare benefit administration companies, SMB retirement plans and 401k options by filling out our short questionnaire below. `,
}

const FORM_DATA = {
	bodyOneTitleText: 'Start Here',
	subtext: 'For Your Free Quote',
	bodyTwoTitleText: 'Looking to set up a 401k for your business?',
	bodyText: 'Start offering retirement benefits to your employees today. We work with brands who offer:',
	bullets: ['SEP-IRA', 'SIMPLE IRA', 'Safe harbor IRA', 'Traditional 401k plan', 'And more!'],
	widgetCategoryId: '10255',
	featureKey: 'pancakes-value-prop',
}

//product name empty because business thought text was redunant with logo.
const PACKAGES_DATA = {
	packagesTitle: 'Employee Retirement Plan Options',
	context: [
		{
			titleText: 'Best for Small Businesses',
			productName: '',
			bullets: ['Variety of retirement plans', 'ADP payroll integration', 'Easy enrollment with mobile app'],
			img: ADP,
		},
		{
			titleText: 'Best for Affordability ',
			productName: '',
			bullets: ['Low monthly employer costs', 'Relatively low employee fees', 'Customizable and flexible plans'],
			img: HumantInterest,
		},
		{
			titleText: 'Best All-In-One Solution',
			productName: '',
			bullets: ['Integration with Paychex payroll', 'Variety of retirement plans', 'Fiduciary support'],
			img: Paychex,
		},
	],
}

const BRAND_DATA = [
	{ img: ADP },
	{ img: American401k },
	{ img: HumantInterest },
	{ img: ShareBuilder },
	{ img: Paychex },
]

const BUYING_GUIDE_DATA = [
	{
		headerText: 'What to Expect in 2022',
		context: [
			{
				paragraph: `The last year has been a rough one for small business owners. The COVID-19 pandemic has wreaked havoc on countless businesses, many of which have closed their doors forever. But there are also businesses that have thrived despite (or because of) the pandemic, which have been growing, hiring, and competing for talent. It has not been easy. Supply-chain disruptions, rising costs, and severe labor shortages are weighing on small businesses, particularly restaurants and other service providers that can't find the staff to serve customers. `,
			},
			{
				paragraph: `While offering employee retirement plans is an effective way to stand out in a competitive labor market, many small business owners don't think they can afford to offer one. They aren't saving for their own retirement, let alone their employees' retirement. According to a pre-pandemic SCORE survey of small business owners with 10 or fewer employees, 72% said they don't offer employees a retirement plan. Meanwhile, 48% of employees said they'd left a company because there was no employer-sponsored way to save for retirement.`,
			},
			{
				paragraph: `The good news is the abundant options for small business owners who want to offer employee retirement plans – and they don't have to break the bank. There are several low-cost plan providers among our best picks, including Human Interest. These companies offer small businesses many features and services previously reserved for larger organizations. One feature becoming popular in the employee retirement plan space is automatic enrollment. With this approach, employers automatically deduct money from employees' wages to go into the company-sponsored retirement savings plan. `,
			},
			{
				paragraph: `Contribution limits are also increasing in 2022, which is good news for savers. Employees age 50 and under can contribute a maximum of $20,500 to a 401(k). The catch-up limit is staying the same at $6,500, which means people over 50 can save a maximum of $27,000. The total contribution limit for employees and employers will increase to $61,000 and to $67,500 for people over 50. IRA contribution and catch-up limits won't increase in 2022.`,
			},
			{
				paragraph: `The labor market is forecast to be tight well into 2022, which means small business owners have to find creative ways to lure talent their way. An employee retirement savings plan is one effective tool. Thanks to competition in the marketplace and tax incentives, offering one is more affordable than ever before.`,
			},
		],
	},
	{
		headerText: 'Employee Retirement Plan Pricing',
		context: [
			{
				paragraph: `When you're evaluating how much an employee retirement plan costs, you need to consider two sets of prices: your costs to sponsor the plan and your employees' costs to participate in it. These costs vary by type of plan and provider. There are also tax incentives associated with some of these plans, which can lower your overall expenditures.`,
			},
			{
				subHeader: `Tax Advantages of Offering Employee Retirement Plans `,
			},
			{
				paragraph: `The government offers tax incentives to small businesses that sponsor retirement plans for their employees. As you calculate what it would cost to sponsor an employee retirement plan for your business, consult your accountant or tax advisor to determine which tax credits and deductions you're eligible for and how they would affect your tax strategy.`,
			},
			{
				bullets: [
					`Eligible employers can claim a tax credit of up to $5,000 for three years for the costs associated with starting a SEP IRA, SIMPLE IRA, or 401(k) plan. A tax credit reduces the taxes you owe on a dollar-for-dollar basis.`,
					`Contributions you make to your employees' plans are tax deductible.`,
					`Contributing to and participating in a retirement plan may lower your income tax bracket.`,
				],
			},
			{
				subHeader: `401(k) Plan Costs`,
			},
			{
				paragraph: `A 401(k) plan used to be rare for small employers to offer, given the costs and complexity of complying with federal regulations. You needed a plan administrator, custodian, recordkeeper and financial advisor. Each of these parties charge fees; some are fixed, while others are based on plan assets. Some companies offer multiple in-house services but charge extra for some of them. Sounds confusing? We think so too.`,
			},
			{
				paragraph: `In our opinion, hiring multiple companies to handle different aspects of the same overall task is burdensome and expensive, so in our search for the best retirement companies, we looked for all-inclusive 401(k) providers that cater to small businesses. They handle all the duties of an administrator, recordkeeper, custodian, and advisor, so you only need to work with one company. They also act as an ERISA 3(38) fiduciary, which lowers your liability risks. Some of these companies use robo-advisors that rely on algorithms to manage assets, allowing them to forgo fund management or advisory fees, so you only pay the fund expense ratio. Most of these companies offer low-fee index funds, ETFs, target-date funds, and mutual funds to keep expense ratios down. Here's an overview of the fees you can expect to pay`,
			},
			{
				boldBullets: [
					`Setup or establishment fee: Most retirement plan companies charge around $500 to set up your plan, but some offer discounts or promotions that reduce or waive this fee. If you're converting an existing plan, this fee is often higher.`,
					`Monthly or annual administration fee: Most retirement services charge this fee, which can run the gamut from $50 to $130 per month, sometimes even higher. Some of the vendors we reviewed charge this fee quarterly.`,
					`Monthly or annual cost per employee: This fee is sometimes included in the monthly administration fee. For those that charge it separately, the average price is around $6 per employee per month. Usually, the employer pays this fee, but sometimes the employee (plan participant) pays it.`,
					`Investment, advisory, custodian or other asset-based fees: Most companies charge some sort of asset-based fee that the plan participant pays. It ranges from fund expense ratios that cost around 0.06% to 0.1% for index-based funds to advisory services that cost up to 0.75% annually. All told, the average investment fees for 401(k) plans are around 1.65%.`,
					`Termination fee: Every provider charges this fee, which is usually around $1,000, if you terminate your plan (though a few companies charge less). Shutting down a plan and transferring assets is more involved on their end than setting up a plan.`,
					`Nonstandard or event-based fees: If plan participants take out loans from their 401(k) plans or reach the age where they're required to take minimum distributions, they'll pay various related fees, such as loan origination and loan maintenance fees.`,
					`ERISA bond (also called a fidelity bond): Small business 401(k) plan providers don't offer this, but you'll be required to have it when you sponsor an employee retirement plan. You should be able to get it from your liability insurance company, or your retirement services provider can recommend a source.`,
				],
			},
			{
				subHeader: `IRA Plan Costs`,
			},
			{
				paragraph: `Employer-sponsored IRAs are much simpler to set up and maintain than 401(k) plans, but they don't allow employees to set aside as much money in their workplace retirement plans. IRAs have a very different pricing structure from 401(k) plans, and there's a lot of variance between brokerages. Here's a sample of the fees you should look out for when setting up this type of employee retirement plan`,
			},
			{
				boldBullets: [
					`Setup fee: Most brokerages don't charge a fee to set up an IRA retirement plan`,
					`Account minimums: Some brokerages have a minimum investment threshold that you must meet to establish an account, but many don't. `,
					`Account service fees: Some brokerages charge an annual fee for each fund if your balance doesn't meet a certain threshold. Waivers may be available`,
					`Investment trade fees: Plan participants pay this fee, or commission, when they trade shares. There's a lot of variance for this fee, depending on the brokerage you use and the funds you trade. Advertised fees for ETFs that you trade online range from $4.95 to $19.95. However, if plan participants trade over the phone or have a broker place the trade for them, this fee is higher. Participants can save money on this fee by choosing from the commission-free trades that many brokerage firms offer.`,
					`Advisor services (optional): Brokerages frequently offer management services for an extra fee. Usually, this fee is a fraction of a percentage of the account balance, but some cost more than 1% of your assets. You may be required to meet account minimums to qualify for this service. However, most brokerages, even those that offer this service, provide complimentary access to brokers who can give your plan participants custom investment advice, helping them select funds and create a sound investment strategy.`,
					`Nonstandard or event-based fees: As with 401(k) plans, participants may pay various fees for actions like withdrawing or transferring money out of their retirement accounts.`,
				],
			},
		],
	},
]

const EmployeeRetirementPlans = () => {
	return (
		<>
			<SQL
				meta={EmployeeRetirementPlansMeta}
				headerData={HEADER_DATA}
				formData={FORM_DATA}
				packagesData={PACKAGES_DATA}
				brandData={BRAND_DATA}
				buyingGuideData={BUYING_GUIDE_DATA}
			/>
		</>
	)
}

export default EmployeeRetirementPlans
